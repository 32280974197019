import React from 'react';
import {
  DateField,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import RelatedImageField from '../../components/RelatedImageField';

export const BrandShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="link" />
      <NumberField source="neighbourhoodId" />
      <RelatedImageField source="file" title="logo" />
      <DateField source="createdDate" />
      <DateField source="updatedDate" />
      <TextField source="deletedDate" />
    </SimpleShowLayout>
  </Show>
);
