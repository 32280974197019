import * as React from 'react';
import {
  Logout,
  MenuItemLink,
  UserMenu /* useLocaleState , useUserMenu, */,
} from 'react-admin';
// import { Link } from 'react-router-dom';
// import MenuItem from '@mui/material/MenuItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';
// import { Language } from '@mui/icons-material';

/* eslint-disable react/jsx-props-no-spreading */
// It's important to pass the ref to allow MUI to manage the keyboard navigation

// const ConfigurationMenu = React.forwardRef((props, ref) => (
//   <MenuItem
//     ref={ref}
//     component={Link}
//     // It's important to pass the props to allow MUI to manage the keyboard navigation
//     {...props}
//     to="/configuration"
//   >
//     <ListItemIcon>
//       <SettingsIcon />
//     </ListItemIcon>
//     <ListItemText>Configuration</ListItemText>
//   </MenuItem>
// ));

// // It's important to pass the ref to allow MUI to manage the keyboard navigation
// const SwitchLanguage = React.forwardRef((props, ref) => {
//   const [locale, setLocale] = useLocaleState();
//   console.log('locale: ', locale);
//   // We are not using MenuItemLink so we retrieve the onClose function from the UserContext
//   const { onClose } = useUserMenu();

//   return (
//     <MenuItem
//       ref={ref}
//       // It's important to pass the props to allow MUI to manage the keyboard navigation
//       {...props}
//       sx={{ color: 'text.secondary' }}
//       onClick={() => {
//         setLocale(locale === 'en' ? 'sp' : 'en');
//         onClose(); // Close the menu
//       }}
//     >
//       <ListItemIcon sx={{ minWidth: 5 }}>
//         <Language />
//       </ListItemIcon>
//       <ListItemText>Switch Language</ListItemText>
//     </MenuItem>
//   );
// });

const CustomUserMenu = (props) => (
  <UserMenu {...props}>
    <MenuItemLink
      to="/profile"
      primaryText="My Profile"
      leftIcon={<SettingsIcon />}
    />
    {/* <SwitchLanguage /> */}
    <Logout />
  </UserMenu>
);

export default CustomUserMenu;
