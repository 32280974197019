import React from 'react';
import {
  Datagrid,
  DateField,
  EditButton,
  EmailField,
  List,
  TextField,
  TextInput,
  usePermissions,
} from 'react-admin';
import RelatedImageField from '../../components/RelatedImageField';
import EnumField from '../../components/EnumField';
import UserRoles from '../../enums/UserRoles';

const postFilters = [
  <TextInput source="email" label="Email" alwaysOn />,
  <TextInput source="firstName" label="First Name" />,
  <TextInput source="lastName" label="Last Name" />,
];

export const UserList = () => {
  const { permissions } = usePermissions();
  const hasEditPermissions = permissions && UserRoles.hasPermission(
    permissions,
    UserRoles.UserRoles.SUPERADMIN,
  );
  return (
    <List filters={postFilters}>
      <Datagrid rowClick="show">
        <RelatedImageField rowImage source="file" title="logo" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <EmailField source="email" />
        <TextField source="username" />
        <TextField source="address" />
        <TextField source="phone" />
        <EnumField source="role" getName={UserRoles.getName} />
        <DateField source="createdDate" />
        {hasEditPermissions && <EditButton />}
      </Datagrid>
    </List>
  );
};
