import React from 'react';
import {
  Create,
  ImageField,
  ImageInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import Neighbourhoods from '../../enums/Neighbourhoods';

export const BrandCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="description" />
      <TextInput source="link" />
      <SelectInput
        source="neighbourhoodId"
        choices={Neighbourhoods.getForSelect()}
        validate={required()}
      />
      <ImageInput
        source="file"
        label="Logo"
        accept="image/*"
        validate={required()}
      >
        <ImageField source="src" title="logo" />
      </ImageInput>
    </SimpleForm>
  </Create>
);
