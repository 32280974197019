import React, { useEffect } from 'react';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import UserIcon from '@mui/icons-material/Group';
import { CardTravel, Highlight } from '@mui/icons-material';
import { Route } from 'react-router-dom';
import dataProvider from '../../providers/DataProvider';
import { UserList } from '../users/UserList';
import Dashboard from '../dashboard/Dashboard';
import authProvider from '../../providers/AuthProvider';
import { UserEdit } from '../users/UserEdit';
import { NotFound } from '../notFound/NotFound';
import { UserCreate } from '../users/UserCreate';
import { UserShow } from '../users/UserShow';
import { languageProvider } from '../../providers/LanguageProvider';
import CustomLayout from '../layout/Layout';
import UserRoles from '../../enums/UserRoles';
import './PageContainer.css';
import { ProfileEdit } from '../profile/ProfileEdit';
import { BrandEdit } from '../brands/BrandEdit';
import { BrandCreate } from '../brands/BrandCreate';
import { BrandShow } from '../brands/BrandShow';
import { BrandList } from '../brands/BrandList';
import { HighlightEdit } from '../highlights/HighlightEdit';
import { HighlightCreate } from '../highlights/HighlightCreate';
import { HighlightShow } from '../highlights/HighlightShow';
import { HighlightList } from '../highlights/HighlightList';
import CustomLogin from '../login/Login';
// import { BrandList } from '../brands/BrandList';

const PageContainer = () => {
  useEffect(() => {}, []);
  // const { permissions } = usePermissions();
  return (
    <Admin
      dashboard={Dashboard}
      catchAll={NotFound}
      layout={CustomLayout}
      loginPage={CustomLogin}
      i18nProvider={languageProvider}
      authProvider={authProvider}
      dataProvider={dataProvider}
      title="Olivos Admin Panel"
      disableTelemetry
      requireAuth
    >
      {(permissions) => {
        const hasSuperAdminPermissions = UserRoles.hasPermission(
          permissions,
          UserRoles.UserRoles.SUPERADMIN,
        );

        return (
          <Resource
            name="users"
            recordRepresentation="Hola"
            options={{ label: 'Usuarios' }}
            icon={UserIcon}
            list={UserList}
            show={UserShow}
            edit={hasSuperAdminPermissions ? UserEdit : null}
            create={hasSuperAdminPermissions ? UserCreate : null}
            // recordRepresentation={(record) => `${record.firstName} ${record.lastName}`}
          />
        );
      }}
      <Resource
        name="highlights"
        recordRepresentation="Highlight"
        options={{ label: 'Destacados' }}
        icon={Highlight}
        list={HighlightList}
        show={HighlightShow}
        edit={HighlightEdit}
        create={HighlightCreate}
      />
      <Resource
        name="brands"
        recordRepresentation="Brands"
        options={{ label: 'Marcas' }}
        icon={CardTravel}
        list={BrandList}
        show={BrandShow}
        edit={BrandEdit}
        create={BrandCreate}
      />
      <Resource name="profile" />
      <CustomRoutes>
        <Route path="/profile" element={<ProfileEdit />} />
      </CustomRoutes>
    </Admin>
  );
};

export default PageContainer;
