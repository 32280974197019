import * as React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';

const Dashboard = () => (
  <Card>
    <CardHeader title="Bienvenido al Panel de Administracion de Olivos" />
    <CardContent>Navegue con el menu lateral para manejar el contenido del sitio</CardContent>
  </Card>
);

export default Dashboard;
