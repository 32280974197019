import { Buffer } from 'buffer';
import { store } from '../redux/store';
import { setUserLoggedIn, logout } from '../state/AppState';
import { actionSignIn } from '../services/AuthService';
import endpoints from '../config/endpoint';

const authProvider = {
  // called when the user attempts to log in
  login: async ({ username, password }) => {
    const tokenString = `${username}:${password}`;
    const base64String = Buffer.from(tokenString).toString('base64');
    store.dispatch(setUserLoggedIn(null, base64String));
    const user = await actionSignIn();
    if (user === false) {
      store.dispatch(logout());
      return Promise.reject();
    }
    store.dispatch(setUserLoggedIn(user, base64String));
    return Promise.resolve();
  },
  // called when the user clicks on the logout button
  logout: () => {
    store.dispatch(logout());
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      return Promise.reject(
        new Error({ redirectTo: '/unauthorized', logoutUser: false }),
      );
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    const state = store.getState();
    return state.app.token ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const state = store.getState();
    return state.app.user
      ? Promise.resolve(state.app.user.role)
      : Promise.reject();
  },
  getIdentity: () => {
    try {
      const state = store.getState();
      const { firstName, lastName, file } = state.app.user;
      let avatar;
      if (file) {
        avatar = endpoints.baseNoApi + file.url;
      }
      return Promise.resolve({
        ...state.app.user,
        fullName: `${firstName} ${lastName}`,
        avatar,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default authProvider;
