import React from 'react';
import { useRecordContext } from 'react-admin';
import endpoints from '../config/endpoint';
import styles from './RelatedImageField.module.css';

const RelatedImageField = ({ title, rowImage }) => {
  const record = useRecordContext();
  let url;
  if (record.url) {
    url = `${endpoints.baseNoApi}${record.url}`;
  } else if (record.src) {
    url = record.src;
  } else if (record.file?.url) {
    url = `${endpoints.baseNoApi}${record.file.url}`;
  } else {
    return null;
  }
  let cssClasses = `${styles.image}`;
  if (rowImage) {
    cssClasses += ` ${styles.rowImage}`;
  }
  return <img className={cssClasses} title={title} alt={title} src={url} />;
};

export default RelatedImageField;
