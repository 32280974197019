import React from 'react';
import { Layout } from 'react-admin';
// import MyAppBar from './MyAppBar';
// import MySidebar from './MySidebar';
// import MyMenu from './MyMenu';
import CustomAppBar from './AppBar';
/* eslint-disable react/jsx-props-no-spreading */

const CustomLayout = (props) => <Layout {...props} appBar={CustomAppBar} />;

export default CustomLayout;
