import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title } from 'react-admin';
import { CardHeader } from '@mui/material';

export const NotFound = () => (
  <Card>
    <Title title="Pagina no existe" />
    <CardContent>
      <CardHeader title="Error 404: Pagina no existe" />
      <CardContent>
        La direccion ingresada no corresponde a una pagina valida
      </CardContent>
    </CardContent>
  </Card>
);
