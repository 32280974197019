/* eslint-disable default-param-last */
// @flow
export const initialState = {
  isLoading: false,
  isLoggedIn: false,
  token: null,
  user: {},
  alert: null,
  myBriefs: [],
};

export const SET_LOADING = 'AppState/SET_LOADING';
export const SET_MY_BRIEFS = 'AppState/SET_MY_BRIEFS';
export const SET_USER_ACTIVE = 'AppState/SET_USER_ACTIVE';
export const LOG_IN = 'AppState/LOG_IN';
export const LOG_OUT = 'AppState/LOG_OUT';
export const SET_ALERT = 'AppState/SET_ALERT';
export const UPDATE_USER = 'AppState/UPDATE_USER';

export function setMyBriefs(briefs) {
  return {
    type: SET_MY_BRIEFS,
    payload: briefs,
  };
}

export function setLoading(status = false) {
  return {
    type: SET_LOADING,
    payload: status,
  };
}

export function setUserLoggedIn(user, token) {
  return {
    type: LOG_IN,
    payload: {
      user,
      token,
    },
  };
}

export function updateUser(user) {
  return {
    type: UPDATE_USER,
    payload: user,
  };
}

export function setAlert(alert) {
  return {
    type: SET_ALERT,
    payload: alert,
  };
}

export function logout() {
  return {
    type: LOG_OUT,
  };
}

const AppStateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_MY_BRIEFS:
      return {
        ...state,
        myBriefs: payload,
      };
    case SET_ALERT:
      return {
        ...state,
        alert: payload,
      };
    case LOG_IN:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        token: payload.token,
      };
    case LOG_OUT:
      return {
        ...state,
        isLoggedIn: false,
        user: {},
        token: null,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: payload,
      };
    default:
      return state;
  }
};

export default AppStateReducer;
