import React from 'react';
import { AppBar } from 'react-admin';
import CustomUserMenu from './UserMenu';
import './AppBar.css';
/* eslint-disable react/jsx-props-no-spreading */

const CustomAppBar = (props) => (
  <AppBar className="appBar" {...props} userMenu={<CustomUserMenu />}>
    <div className="appBarContainer">
      <img src="/images/site/logo.png" className="appBarLogo" alt="logo" />
    </div>
  </AppBar>
);

export default CustomAppBar;
