import polyglotI18nProvider from 'ra-i18n-polyglot';
import raLanguageSpanish from 'ra-language-spanish';
// import englishMessages from 'ra-language-english';

// https://marmelab.com/react-admin/Translation.html

const mySpanishTranslations = {
  ...raLanguageSpanish,
  ra: {
    ...raLanguageSpanish.ra,
    action: {
      ...raLanguageSpanish.ra.action,
      export: 'Exportar',
    },
    navigation: {
      ...raLanguageSpanish.ra.navigation,
      page_rows_per_page: 'Resultados por pagina',
    },
    page: {
      ...raLanguageSpanish.ra.page,
      empty: 'No hay registros actualmente.',
      invite: 'Haga click en el boton CREAR para crear un nuevo registro',
    },
  },
  resources: {
    users: {
      name: 'Usuario |||| Usuarios',
      fields: {
        file: 'Imagen',
        firstName: 'Nombre',
        lastName: 'Apellido',
        email: 'Email',
        username: 'Usuario',
        address: 'Direccion',
        phone: 'Telefono',
        role: 'Rol',
        createdDate: 'Fecha Creacion',
      },
    },
    brands: {
      name: 'Marca |||| Marcas',
      fields: {
        file: 'Logo',
        name: 'Nombre',
        description: 'Descripcion',
        link: 'Link',
      },
    },
    highlights: {
      name: 'Destacado |||| Destacados',
      fields: {
        file: 'Imagen',
        title: 'Titulo',
        subtitle: 'Subtitulo',
        neighbourhoodId: 'Barrio',
      },
    },
  },
};

export const languageProvider = polyglotI18nProvider(
  () => mySpanishTranslations,
  'sp',
);
