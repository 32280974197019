import React from 'react';
import {
  Edit,
  ImageInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import RelatedImageField from '../../components/RelatedImageField';
import Neighbourhoods from '../../enums/Neighbourhoods';

export const HighlightEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="title" validate={required()} />
      <TextInput source="subtitle" />
      <SelectInput
        source="neighbourhoodId"
        choices={Neighbourhoods.getForSelect()}
        validate={required()}
      />
      <ImageInput
        source="file"
        label="Image"
        accept="image/*"
        validate={required()}
      >
        <RelatedImageField source="file" title="image" />
      </ImageInput>
      <ImageInput
        source="mobileFile"
        label="Mobile Image"
        accept="image/*"
        validate={required()}
      >
        <RelatedImageField source="mobileFile" title="mobile image" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);
