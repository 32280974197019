import React from 'react';
import {
  Datagrid, EditButton, List, TextField, TextInput,
} from 'react-admin';
import EnumField from '../../components/EnumField';
import RelatedImageField from '../../components/RelatedImageField';
import Neighbourhoods from '../../enums/Neighbourhoods';

const postFilters = [
  <TextInput source="title" label="Titulo" alwaysOn />,
  <TextInput source="neighbourhoodId" label="Barrio" alwaysOn />,
];

export const HighlightList = () => (
  <List filters={postFilters}>
    <Datagrid rowClick="show">
      <RelatedImageField rowImage source="file" title="Image" />
      <TextField source="title" />
      <TextField source="subtitle" />
      <EnumField source="neighbourhoodId" getName={Neighbourhoods.getName} />
      <EditButton />
    </Datagrid>
  </List>
);
