import React from 'react';
import { Login } from 'react-admin';

const CustomLogin = () => (
  <Login
    // A random image that changes everyday
    backgroundImage="/images/site/loginBackground.png"
  />
);

export default CustomLogin;
