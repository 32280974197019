const UserRoles = {
  USER: 1,
  ADMIN: 2,
  SUPERADMIN: 3,
};

const hasPermission = (userRole, targetRole) => userRole >= targetRole;

const getForSelect = () => [
  { id: 1, name: 'Usuario' },
  { id: 2, name: 'Administrador' },
  { id: 3, name: 'Super Admin' },
];

const getName = (role) => {
  switch (role) {
    case UserRoles.USER:
      return 'Usuario';
    case UserRoles.ADMIN:
      return 'Administrador';
    case UserRoles.SUPERADMIN:
      return 'Super Admin';
    default:
      return '';
  }
};

export default {
  UserRoles,
  getName,
  hasPermission,
  getForSelect,
};
