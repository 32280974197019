const Neighbourhoods = {
  POLO: 1,
  HORNEROS: 2,
};

const getName = (role) => {
  switch (role) {
    case Neighbourhoods.POLO:
      return 'Polo';
    case Neighbourhoods.HORNEROS:
      return 'Horneros';
    default:
      return '';
  }
};

const getForSelect = () => [
  { id: 1, name: getName(1) },
  { id: 2, name: getName(2) },
];

export default {
  Neighbourhoods,
  getName,
  getForSelect,
};
