import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import { store } from '../redux/store';
import { updateUser } from '../state/AppState';
import endpoints from '../config/endpoint';
import axios from '../tools/api';

const apiUrl = endpoints.base;
const httpClient = fetchUtils.fetchJson;

const dataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const { data } = await axios.get(url);
    return {
      data: data.data,
      total: data.total,
    };
  },

  getOne: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    const { data } = await axios.get(url);
    return { data };
  },

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const { data } = await axios.get(url);
    return {
      data: data.data,
      total: data.total,
    };
  },

  update: async (resource, params) => {
    const newParams = { ...params };
    const url = `${apiUrl}/${resource}/${params.id}`;
    let additionalHeaders = {};
    if (params?.data?.file) {
      axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
      newParams.data.file = newParams.data.file.rawFile;
      additionalHeaders = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
    }
    if (params?.data?.mobileFile) {
      axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
      newParams.data.mobileFile = newParams.data.mobileFile.rawFile;
      additionalHeaders = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
    }
    const { data } = await axios.put(url, newParams.data, additionalHeaders);
    return { data };
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  create: async (resource, params) => {
    const newParams = { ...params };
    let additionalHeaders = {};
    const url = `${apiUrl}/${resource}`;
    if (params?.data?.file) {
      axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
      newParams.data.file = newParams.data.file.rawFile;
      additionalHeaders = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
    }
    if (params?.data?.mobileFile) {
      axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
      newParams.data.mobileFile = newParams.data.mobileFile.rawFile;
      additionalHeaders = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
    }
    const { data } = await axios.post(url, params.data, additionalHeaders);
    return { data: { ...params.data, id: data.id } };
  },
  delete: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    const { data } = await axios.delete(url);
    return { data };
  },
  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }));
  },
  getUserProfile() {
    const state = store.getState();
    const { user } = state.app;
    if (user) {
      return Promise.resolve({
        data: user,
      });
    }
    return Promise.reject();
  },
  updateUserProfile: async (params) => {
    const newParams = { ...params };
    const url = `${apiUrl}/users/me`;
    let additionalHeaders = {};
    if (params?.data?.file) {
      axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
      newParams.data.file = newParams.data.file.rawFile;
      additionalHeaders = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
    }
    const { data } = await axios.put(url, newParams.data, additionalHeaders);
    store.dispatch(updateUser(data));
    return Promise.resolve({ data });
  },
};

export default dataProvider;
