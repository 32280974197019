// https://marmelab.com/blog/2020/12/14/react-admin-v3-userprofile.html
import React, { useCallback, useMemo, useState } from 'react';
import {
  TextInput,
  SimpleForm,
  required,
  useGetIdentity,
  useNotify,
  useDataProvider,
  SaveContextProvider,
  DateInput,
  ImageInput,
  NumberInput,
} from 'react-admin';
import OnlySaveToolbar from '../../components/OnlySaveToolbar';
import RelatedImageField from '../../components/RelatedImageField';

export const ProfileEdit = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [saving, setSaving] = useState();
  const { isLoading, identity } = useGetIdentity();

  const handleSave = useCallback(
    async (values) => {
      setSaving(true);
      try {
        await dataProvider.updateUserProfile({ data: values });
        setSaving(false);
        notify('Your profile has been updated', 'info', {
          _: 'Your profile has been updated',
        });
      } catch {
        setSaving(false);
        notify(
          'A technical error occured while updating your profile. Please try later.',
          'warning',
          {
            _: 'A technical error occured while updating your profile. Please try later.',
          },
        );
      }
    },
    [dataProvider, notify],
  );

  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving,
    }),
    [saving, handleSave],
  );

  if (isLoading) {
    return null;
  }

  return (
    <SaveContextProvider value={saveContext}>
      <SimpleForm
        toolbar={<OnlySaveToolbar />}
        save={handleSave}
        record={identity || {}}
      >
        <TextInput source="id" disabled />
        <TextInput source="email" disabled />
        <TextInput source="username" disabled />
        <NumberInput source="role" disabled />
        <TextInput source="firstName" validate={required()} />
        <TextInput source="lastName" validate={required()} />
        <TextInput source="address" validate={required()} />
        <TextInput source="phone" />
        <DateInput source="birthdate" />
        <ImageInput source="file" label="Avatar" accept="image/*">
          <RelatedImageField source="file" title="avatar" />
        </ImageInput>
      </SimpleForm>
    </SaveContextProvider>
  );
};
