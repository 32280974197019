import axios from '../tools/api';
import endpoints from '../config/endpoint';

const actionSignIn = async () => {
  try {
    const { data } = await axios.get(endpoints.signIn);
    return data;
  } catch (error) {
    console.error('Error on login');
    return false;
  }
};

export { actionSignIn };
