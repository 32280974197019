import React from 'react';
import {
  Create,
  ImageField,
  ImageInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import Neighbourhoods from '../../enums/Neighbourhoods';

export const HighlightCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="title" validate={required()} />
      <TextInput source="subtitle" />
      <SelectInput
        source="neighbourhoodId"
        choices={Neighbourhoods.getForSelect()}
        validate={required()}
      />
      <ImageInput
        source="file"
        label="Image"
        accept="image/*"
        validate={required()}
      >
        <ImageField source="src" title="image" />
      </ImageInput>
      <ImageInput
        source="mobileFile"
        label="Mobile Image"
        accept="image/*"
        validate={required()}
      >
        <ImageField source="src" title="mobile image" />
      </ImageInput>
    </SimpleForm>
  </Create>
);
