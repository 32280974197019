import React from 'react';
import {
  DateField,
  EmailField,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import RelatedImageField from '../../components/RelatedImageField';

export const UserShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <EmailField source="email" />
      <TextField source="username" />
      <TextField source="address" />
      <DateField source="phone" />
      <TextField source="birthdate" />
      <NumberField source="role" />
      <RelatedImageField source="file" title="avatar" />
      <DateField source="createdDate" />
      <DateField source="updatedDate" />
      <TextField source="deletedDate" />
    </SimpleShowLayout>
  </Show>
);
