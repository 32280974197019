import React from 'react';
import {
  DateInput,
  Edit,
  ImageInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import RelatedImageField from '../../components/RelatedImageField';
import UserRoles from '../../enums/UserRoles';

export const UserEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="firstName" validate={required()} />
      <TextInput source="lastName" validate={required()} />
      <TextInput source="email" validate={required()} />
      <TextInput source="username" validate={required()} />
      <TextInput source="address" validate={required()} />
      <TextInput source="phone" />
      <DateInput source="birthdate" />
      <ImageInput source="file" label="Avatar" accept="image/*">
        <RelatedImageField source="file" title="avatar" />
      </ImageInput>
      <SelectInput
        source="role"
        choices={UserRoles.getForSelect()}
        validate={required()}
      />
    </SimpleForm>
  </Edit>
);
