import React from 'react';
import {
  Datagrid,
  EditButton,
  List,
  TextField,
  TextInput,
} from 'react-admin';
import EnumField from '../../components/EnumField';
import RelatedImageField from '../../components/RelatedImageField';
import Neighbourhoods from '../../enums/Neighbourhoods';

const postFilters = [<TextInput source="name" label="Name" alwaysOn />];

export const BrandList = () => (
  <List filters={postFilters}>
    <Datagrid rowClick="show">
      <RelatedImageField rowImage source="file" title="logo" />
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="link" />
      <EnumField source="neighbourhoodId" getName={Neighbourhoods.getName} />
      <EditButton />
    </Datagrid>
  </List>
);
