// import AsyncStorage from '@react-native-community/async-storage';
import axios from 'axios';
import { store } from '../redux/store';
// import Config from './constants';

/**
 * Axios defaults
 */
// axios.defaults.baseURL = Config.API;

// Headers
axios.defaults.headers.common['Content-Type'] = 'application/json';
// axios.defaults.headers.common['Access-Control-Allow-Credentials'] = '*';
axios.defaults.headers.common.Accept = 'application/json';

/**
 * Request Interceptor
 */
axios.interceptors.request.use(
  async (inputConfig) => {
    const config = inputConfig;
    // Check for and add the stored Auth Token to the header request
    let token = '';
    try {
      const state = store.getState();
      token = state.app.token;
    } catch (error) {
      console.log('no token');
      /* Nothing */
    }
    if (token) {
      config.headers.common.Authorization = `Basic ${token}`;
    }

    return config;
  },
  (error) => {
    throw error;
  },
);

/**
 * Response Interceptor
 */
// axios.interceptors.response.use(
//   (res) => {
//     // Status code isn't a success code - throw error
//     if (!`${res.status}`.startsWith('2')) {
//       alert(
//         'error',
//         res.data.errorMessageTitle
//           ? res.data.errorMessageTitle
//           : 'Unexpected error',
//         res.data.errorMessage
//           ? res.data.errorMessage
//           : 'Something went wrong processing your request.'
//       );
//       store.dispatch(setLoading(false));
//       throw res.data;
//     }
//     // Otherwise just return the data
//     if (res.data.successMessage) {
//       alert(
//         'success',
//         res.data.successMessageTitle
//           ? res.data.successMessageTitle
//           : 'Success!',
//         res.data.successMessage
//       );
//     }
//     store.dispatch(setLoading(false));
//     return res;
//   },
//   (error) => {
//     // Pass the response from the API, rather than a status code
//     const errorInfo = error.response.data;
//     if (errorInfo && errorInfo.error) {
//       alert(
//         'error',
//         errorInfo.errorMessageTitle ? errorInfo.errorMessageTitle : 'Oops...',
//         errorInfo.errorMessage
//           ? errorInfo.errorMessage
//           : 'Something went wrong processing your request.'
//       );
//       store.dispatch(setLoading(false));
//       throw errorInfo;
//     }
//     alert(
//       'error',
//       'Unexpected error',
//       'Something went wrong processing your request.'
//     );
//     store.dispatch(setLoading(false));
//     throw error;
//   }
// );

export default axios;
