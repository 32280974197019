import * as React from 'react';
import { SaveButton, Toolbar } from 'react-admin';
/* eslint-disable react/jsx-props-no-spreading */

const OnlySaveToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export default OnlySaveToolbar;
