import React from 'react';
import {
  Create,
  DateInput,
  ImageField,
  ImageInput,
  PasswordInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import UserRoles from '../../enums/UserRoles';

export const UserCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="firstName" validate={required()} />
      <TextInput source="lastName" validate={required()} />
      <TextInput source="email" validate={required()} />
      <PasswordInput source="password" validate={required()} />
      <TextInput source="username" validate={required()} />
      <TextInput source="address" />
      <TextInput source="phone" />
      <DateInput source="birthdate" />
      <ImageInput source="file" label="Avatar" accept="image/*">
        <ImageField source="src" title="avatar" />
      </ImageInput>
      <SelectInput
        source="role"
        choices={UserRoles.getForSelect()}
        validate={required()}
      />
    </SimpleForm>
  </Create>
);
