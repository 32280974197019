import React from 'react';
import {
  Edit,
  ImageInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import RelatedImageField from '../../components/RelatedImageField';
import Neighbourhoods from '../../enums/Neighbourhoods';

export const BrandEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="name" validate={required()} />
      <TextInput multiline source="description" />
      <TextInput source="link" />
      <SelectInput
        source="neighbourhoodId"
        choices={Neighbourhoods.getForSelect()}
        validate={required()}
      />
      <ImageInput
        source="file"
        label="Logo"
        accept="image/*"
        validate={required()}
      >
        <RelatedImageField source="file" title="logo" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);
